<template>
  <div
    class="relative rounded-lg w-full shadow-lg mx-2 md:max-h-80 max-h-72 overflow-hidden hover:scale-105 transition duration-400 ease-in-out"
    style="height: 90%"
  >
  <div class="absolute left-2 md:left-6 top-1" v-show="item.label">
      <div class="flex justify-between space-between h-7">
        <div
          class="align-baseline nowrap rounded-sm text-center py-0.3 mt-2 mr-1 text-sm px-3 inline font-normal btn-color md:text-md text-white truncate">
          {{ item.label }}
        </div>
      </div>
    </div>
    <router-link :to="`/watch/${item.id}`" class="">
      <img
        ref="image"
        class="rounded-lg object-cover w-full h-full shadow-lg z-40"
        :src="defaultImage"
        :data-src="image(item)"
        width="100"
        height="100"
        :alt="item.title"
        style="width: 96%"
        loading="lazy"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['item', 'className', 'index'],
  data () {
    return {
s3: 'https://nasna-s3.s3.me-central-1.amazonaws.com/eposides/',
      defaultImage: require('@/assets/placeholder.webp'),
      observer: null
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // Adjust as needed
    })

    this.observer.observe(this.$refs.image)
  },
  methods: {
    image (item) {
      if (item.image) {
        return this.s3 + item.image
      } else {
        var images = require.context('@/assets/', false, /\.webp$/)
        return images('./' + 'default.webp')
      }
    },
    handleIntersection (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target
          img.src = img.dataset.src // Add this line to set the src attribute
          this.observer.unobserve(img)
        }
      })
    }
  }
}
</script>

<style>
img {
  pointer-events: none;
}
</style>
