<template>
  <div v-if="assetData">
    <div class="media-parent">
      <div class="media-child">
        <video ref="videoPlayer" class="video-js vjs-fluid" controls preload="auto"  :poster="poster" playsinline webkit-playsinline controlsList="nodownload">
          <template v-if="assetData.video_type=='mp4'">
          <template v-if="assetData.video_qualities.length > 0">
            <source v-for="(video, index) in assetData.video_qualities" :key="index" :src="video.url"           v-bind:default="video.default ? true : null"  :res="getRes(video.quality)" :label="video.quality" :type="getType(assetData.video_type)">

          </template>
          <template v-else>  
                    <source :src="assetData.video_url"  :type="getType(assetData.video_type)">
</template>
          </template>
          <template v-if="assetData.video_type=='hls'">
            <source :src="assetData.video_url" type="application/x-mpegURL">

          </template>
          <template v-else>
            <source :src="assetData.video_url"  :type="getType(assetData.video_type)">
          </template>
        </video>
      </div>
    </div>
  </div>
</template>

<script>
/* global videojs */

export default {
  props: ['assetData','next'],
  data(){
    return{
      poster : null,
     s3: 'https://nasna-s3.s3.me-central-1.amazonaws.com/eposides/',
    }
  },
  mounted() {
   setTimeout(()=>{
    this.initializePlayer()
    if (this.assetData && this.assetData.image_full_url != null ) {
          return this.poster = this.assetData.image_full_url;
          
        }
   },2000)
  },
  watch: {
    assetData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializePlayer();
        }
      }
    }
  },
  methods: {
    getPoster() {
        if (this.assetData && this.assetData.video_type !== "audio") {
          return this.poster = `${this.s3}${this.assetData.image}`;
        } else {
          return this.poster;
        }
      },
    getRes(res) {
      return res.replace(/p/, "");
    },
    getType(type){
      if(type=="audio") return "audio/mp3";
      if(type=="mp4"){
        return "video/mp4";
      }else {
        return "application/x-mpegUR"
        
      }
      //
    },
    handleNext() {
      this.$router.push({ name: "watch", params: { id: this.next.id } })
    },
    initializePlayer() {
      if (this.$refs.videoPlayer) {
        const player = videojs(this.$refs.videoPlayer, { language: 'ar' });
        player.nuevo({
          themeColor: '#9d2890',
          video_id: `${this.assetData.id}`,
          resume: false,
          
          shareMenu: false,
          zoomMenu: false,
          buttonRewind: true,
      

        });
        player.hotkeys({
		volumeStep: 0.1,
		seekStep: 5
	});
  player.on('ended', () => {
      this.handleNext();
    });
        if(this.assetData.video_type=="audio"){
        
          player.visualizer({video:true,caps:true});

        }

      }
    },
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  created(){
    
       
      
  }
}
</script>

<style>
.video-js .vjs-big-play-button {
  background-color: #9d2890 !important;
}

.video-js .vjs-control-bar .vjs-progress-holder .vjs-play-progress,
.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level {
  background-color: #9d2890 !important;
}


.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level:before {
  background-color: #9d2890 !important;
}
.video-js .vjs-control-bar .vjs-slider .vjs-play-progress .vjs-seek-handle {
  background-color: #9d2890 !important;
}

@media (max-width: 768px) {
    .vjs-quality-button.vjs-menu-button.vjs-control.vjs-button {
        display: block !important;
        visibility: visible !important;
    }
}
</style>
